  /* global.css */
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  body {
    font-family: Arial, sans-serif;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  img {
    max-width: 100%;
  }
  
  button {
    font-family: 'Roboto', sans-serif;
    border: none;
    cursor: pointer;
  }
  